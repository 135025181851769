import React, { Component } from "react";
import { Table } from "react-bootstrap";
import categorydata from "../../data/categories";
import ProductGallery from "./ProductGallery";

class ProductList extends Component {
  handlePopulateTable = () => {
    const productCategory = {};
    this.props.products.forEach(record => {
      if (!productCategory[record.category]) {
        productCategory[record.category] = [];
      }
      productCategory[record.category].push(record);
    });
    const productTable = categorydata
      .filter(record => {
        return productCategory[record.code];
      })
      .map(record => {
        const productItems = productCategory[record.code].map(productItem => {
          return (
            <tr key={productItem.uid} id={`product_${productItem.uid}`}>
              <td className="text-left">{productItem.product_name}</td>
              <td className="text-left">
                {productItem.product_othername.join(" ; ")}
              </td>
              <td>
                {productItem.images && productItem.images.length > 0 ? (
                  <a
                    href={`/products/${productItem.product_name}/images`}
                    className=""
                    onClick={e => this.handleViewImage(e, productItem)}
                  >
                    <u> View Image</u>
                  </a>
                ) : null}
              </td>
              <td>
                {productItem.brochure ? (
                  <a
                    href={`/assets/img/brochures/${productItem.category}/${productItem.brochure}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    <u> View Brochure</u>
                  </a>
                ) : null}
              </td>
            </tr>
          );
        });
        return (
          <React.Fragment key={record.code}>
            <tr>
              <td
                colSpan="4"
                style={{ backgroundColor: "#ff7f00" }}
                className="text-black text-left font-weight-bold text-white"
              >
                {record.name}
              </td>
            </tr>
            {productItems}
          </React.Fragment>
        );
      });
    return productTable;
  };

  handleViewImage = (e, productItem) => {
    e.preventDefault();
    this.props.viewImages(productItem);
  };

  handleCloseGallery = () => {
    this.props.closeGallery();
  };

  render() {
    const productTable = this.handlePopulateTable();
    const { galleryImages, showGallery } = this.props.productGallery;
    return (
      <>
        <div className="table-responsive">
          <Table striped>
            <thead>
              <tr>
                <th>Product</th>
                <th>Description</th>
                <th>Images</th>
                <th>Brochure</th>
              </tr>
            </thead>
            <tbody>{productTable}</tbody>
          </Table>
          <ProductGallery
            images={galleryImages}
            showGallery={showGallery}
            handleCloseGallery={this.handleCloseGallery}
          />
        </div>
      </>
    );
  }
}

export default ProductList;
