import React, { Component } from "react";
class CompanyProfile extends Component {
  state = {};
  render() {
    return (
      <section id="about" className="about" style={{ paddingTop: "10px" }}>
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row no-gutters">
            <div className="col-lg-12 d-flex flex-column justify-content-center about-content">
              <div className="section-title">
                <div className="float-left d-none d-lg-block">
                  <img
                    src="/assets/img/logo-2.png"
                    className="img-fluid "
                    alt="About"
                    style={{
                      height: "366px",
                      width: "544px"
                    }}
                  />
                  <h1
                    style={{
                      fontFamily: "Arial",
                      marginTop: "-6px",
                      marginLeft: "-15px"
                    }}
                  >
                    SINCE 1973
                  </h1>
                </div>
                <h2
                  style={{
                    color: "#ff7f00"
                  }}
                >
                  About Us
                </h2>
                <p className="text-justify">
                  RC New Chemical Resources, Inc. was established on April 1,
                  1973 and was later on incorporated on July 10, 1974. The
                  company was founded by Sergio C. Gatmaitan, a Chemical
                  Engineering graduate of FEATI University. Engineer Gatmaitan
                  graduated Magna Cum Laude and copped second place in the
                  Chemical Engineers Board Exam given on July 1969 by the Board
                  of Examiners for Chemical Engineers
                </p>
                <br />
                <p className="text-justify">
                  RC New Chemical Resources, Inc. started in the 1970s by
                  manufacturing concrete stabilizing agents and waterproofing
                  additives using locally available chemicals. The company's two
                  main products back then were RC Epicure (Wax-based Cement
                  Curing Compound) and RC Waterplug (Cement-based Waterproofing
                  Agent). In the late 1980s, the company became affiliated with
                  Crown Zellerbach Corporation in Vancouver, Washington, USA to
                  further enhance its construction chemical knowledge and
                  improve its products. Being one of the pioneers in the
                  country, RC New Chemical Resources, Inc, became known in the
                  industry as one of the best producers of concrete chemical
                  additives and concrete bonding epoxies. Subsequently, the
                  business broadened its product lines by associating with Lane
                  Laboratories, International in Canoga Park, California, USA
                  for Preventive Maintenance and Institutional Chemicals.
                </p>
                <br />
                <p className="text-justify">
                  In the 1990s, RC developed its own products and started
                  importing raw materials from Asia, Europe, North America and
                  South America. Further improving its Research and Development
                  Division, the company locally manufactured its products with
                  imported supplies. Products undergo at least three (3)
                  examination, analysis and testing cycles before the company
                  markets them to ensure international quality standards and
                  local DPWH requirements. To date, the business continues to
                  grow and improve its products to further suit the requirements
                  and improve the quality of infrastructures in the country. The
                  company provides technical assistance to its customers
                  regarding the use of its various products.
                </p>
                <br />
                <p className="text-justify">
                  RC New Chemical Resources, Inc, is currently known for being
                  one of the top manufacturers high-class quality construction
                  chemicals and materials. Being in the industry since 1973, the
                  business stands out as it also offers a diversified approach
                  in the application of these products to help construction
                  companies rapidly produce foundations of infrastructures with
                  international standards
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CompanyProfile;
