import React, { Component } from "react";
import Topbar from "./Topbar";
import Header from "./Header";
class Navigation extends Component {
  state = {};
  render() {
    return (
      <>
        <Topbar />
        <Header />
      </>
    );
  }
}

export default Navigation;
