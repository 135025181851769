import React, { Component } from "react";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import OurProducts from "./OurProducts";
import OurCustomers from "./OurCustomers";
import ContactUs from "./ContactUs";
import PageMeta from "../Common/PageMeta";
class Home extends Component {
  state = {};
  render() {
    return (
      <>
        <PageMeta />
        <div id="Home">
          <Banner />
          <main id="main">
            <AboutUs />
            <OurProducts />
            <OurCustomers />
            <ContactUs {...this.props} />
          </main>
        </div>
      </>
    );
  }
}

export default Home;
