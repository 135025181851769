import React from "react";
import { Helmet } from "react-helmet";
function PageMeta(props) {
  const defaultTitle =
    "RC New Chemical Resources, Inc. - The Leading Supplier of Chemical and Construction Material - Since 1973";
  const attachedTitle = "RC New Chemical Resources, Inc.";
  const { title, description } = props;
  return (
    <>
      <Helmet>
        <title>{title ? `${title} - ${attachedTitle}` : defaultTitle}</title>
        <meta
          name="description"
          content={
            description ? `${description} of ${defaultTitle}` : defaultTitle
          }
        />
      </Helmet>
    </>
  );
}
export default PageMeta;
