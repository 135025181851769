import React, { Component } from "react";
class Footer extends Component {
  state = {};

  doNothing = e => {
    e.preventDefault();
  };

  render() {
    return (
      <>
        <footer id="footer">
          <div className="footer-top bg-dark">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-info">
                  <h3>RC New Chemical Resources, Inc.</h3>
                  <p>
                    G/F Sertesa Bdlg., No. 73 Clemente Jose St. Malibay, Pasay
                    City – 1300 Philippines
                    <br />
                    <br />
                    <strong>Phone:</strong> +63 2 8851-04-86
                    <br />
                    <span className="ml-5 p-1"> +63 2 8851-04-92</span>
                    <br />
                    <span className="ml-5 p-1"> +63 2 8851-09-47</span>
                    <br />
                    <span className="ml-5 p-1"> +63 2 8851-05-01</span>
                    <br />
                    <strong className="mr-1">Email:</strong>{" "}
                    sales@rcnewchem.com.ph
                    <br />
                    <span className="ml-5 ">office@rcnewchem.com.ph</span>
                  </p>
                </div>

                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href="/companyprofile">Company Profile</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="/products">Products</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="/contactus">Contact Us</a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a onClick={this.doNothing} href="/#">
                        Importation
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a onClick={this.doNothing} href="/#">
                        Manufacture
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a onClick={this.doNothing} href="/#">
                        Supply
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="copyright text-dark">
              &copy; Copyright
              <strong>
                <span> RC New Chemical Resources, Inc.</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
