import React, { Component } from "react";
class Header extends Component {
  state = {};
  render() {
    return (
      <header id="header">
        <div className="container">
          <div className="logo float-left">
            <a href="/">
              <img
                src="/assets/img/logo.png"
                className="float-left img-fluid"
                alt="Logo"
              />
              <h1 className="text-bold float-left d-none d-sm-none d-md-block">
                RC New Chemical Resources, Inc.
              </h1>
              <div className="text-bold float-left d-md-none">
                RC New Chemical Resources, Inc.
              </div>
            </a>
            {/* <!-- Uncomment below if you prefer to use an image logo --> */}
            {/* <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>--> */}
          </div>

          <nav className="nav-menu float-right d-none d-lg-block">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/companyprofile">Company Profile</a>
              </li>
              <li>
                <a href="/products">Products</a>
              </li>
              <li>
                <a href="/contactus">Contact Us</a>
              </li>
            </ul>
          </nav>
          <nav className="mobile-nav d-lg-none ">
            <ul>
              <li>
                <a href="/#">Home</a>
              </li>
              <li>
                <a href="/companyprofile">Company Profile</a>
              </li>
              <li>
                <a href="/products">Products</a>
              </li>
              <li>
                <a href="/contactus">Contact Us</a>
              </li>
            </ul>
          </nav>
          <button
            type="button"
            className="mobile-nav-toggle d-lg-none"
            style={{ marginTop: "-20px", marginRight: "-30px", zIndex: 1 }}
          >
            <i className="icofont-navigation-menu"></i>
          </button>
          <div className="mobile-nav-overly"></div>
        </div>
      </header>
    );
  }
}

export default Header;
