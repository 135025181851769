import React, { Component } from "react";
class Banner extends Component {
  state = {};
  render() {
    return (
      <section id="hero">
        <div className="hero-container">
          <div
            id="heroCarousel"
            className="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <ol
              className="carousel-indicators"
              id="hero-carousel-indicators"
            ></ol>

            <div className="carousel-inner" role="listbox">
              {/* <!-- Slide 1 --> */}
              <div
                className="carousel-item active"
                style={{
                  backgroundImage: "url('/assets/img/slide/slide-5.png')"
                }}
              >
                <div className="carousel-container">
                  <div className="carousel-content container">
                    <img
                      src="/assets/img/logo-2.png"
                      className="mx-auto d-block"
                      alt="Logo"
                      style={{
                        height: "100px",
                        width: "123px"
                      }}
                    />
                    <div
                      className="font-weight-bold text-center text-white"
                      style={{
                        fontFamily: "Arial",
                        marginTop: "-6px"
                      }}
                    >
                      SINCE 1973
                    </div>
                    <h2 className="animated fadeInDown text-bold text-center text-white">
                      RC New Chemical Resources, Inc.
                    </h2>
                    <h3 className="animated fadeInUp text-bold text-center text-white">
                      Importer <b>&middot;</b> Manufacturer <b>&middot;</b>{" "}
                      Supplier of construction chemicals and materials
                    </h3>
                    <div className="text-center">
                      <a
                        href="/products"
                        className="btn-get-started animated fadeInUp scrollto "
                      >
                        View Products
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Slide 2 --> */}
              <div
                className="carousel-item"
                style={{
                  backgroundImage: "url('/assets/img/slide/slide-2.jpg')"
                }}
              >
                <div className="carousel-container">
                  <div className="carousel-content container">
                    <h2 className="animated fadeInDown">
                      High Quality Construction Materials
                    </h2>
                    <p className="animated fadeInUp">
                      RC New Chemical Resources Inc is currently known for being
                      one of the top manufacturers high-class quality
                      construction chemicals and materials
                    </p>
                    <a
                      href="/products"
                      className="btn-get-started animated fadeInUp scrollto"
                    >
                      View Products
                    </a>
                  </div>
                </div>
              </div>

              {/* <!-- Slide 3 --> */}
              <div
                className="carousel-item"
                style={{
                  backgroundImage: "url('/assets/img/slide/slide-3.jpg')"
                }}
              >
                <div className="carousel-container">
                  <div className="carousel-content container">
                    <h2 className="animated fadeInDown">
                      Local and International Distribution
                    </h2>
                    <p className="animated fadeInUp">
                      Our products are shipped locally in the Philippines, and
                      worldwide
                    </p>
                    <a
                      href="/products"
                      className="btn-get-started animated fadeInUp scrollto"
                    >
                      View Products
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <a
              className="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon icofont-rounded-left"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon icofont-rounded-right"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
