import React, { Component } from "react";
class MissionVision extends Component {
  state = {};
  render() {
    return (
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row no-gutters">
            <div className="col-lg-12 d-flex flex-column justify-content-center about-content">
              <div className="section-title">
                <h2
                  style={{
                    color: "#ff7f00"
                  }}
                >
                  Mission and Vision
                </h2>
                <p className="text-justify">
                  RC New Chemical Resources, Inc. aims to provide the
                  Philippines construction industry with construction chemicals
                  and materials of world-class quality at an affordable price.
                  With the company's research and development, it aims to
                  educate local contractors on how to build infrastructures on
                  top-notch quality.
                </p>
                <br />
                <p className="text-justify">
                  Being one of the top manufacturers of construction chemicals,
                  the company is planning to diversify in other fields in
                  construction to meet the needs of the industry and expand its
                  horizons on how to improve the value of living in the country.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MissionVision;
