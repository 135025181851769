import React, { Component } from "react";
import { Modal } from "react-bootstrap";

function withInfoModal(WrappedComponent) {
  return class LoadingModal extends Component {
    handleClose = () => {
      this.props.setInfoModal({
        show: false,
        title: "",
        message: ""
      });
    };
    render() {
      const { show, title, message } = this.props.infoModal;
      return (
        <>
          <WrappedComponent {...this.props} />
          <Modal show={show} onHide={this.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
          </Modal>
        </>
      );
    }
  };
}

export default withInfoModal;
