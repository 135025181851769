import React, { useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

export function Init() {
  const [isLoading, setIsLoading] = useState(false);
  const [infoModal, setInfoModal] = useState({
    show: false,
    title: "",
    message: ""
  });
  return (
    <App
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      infoModal={infoModal}
      setInfoModal={setInfoModal}
    />
  );
}
ReactDOM.render(<Init />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
