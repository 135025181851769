import React, { Component } from "react";
import ProductContact from "./ProductContact";
import ProductSearch from "./ProductSearch";
import ProductList from "./ProductList";
import PageHeader from "../Common/PageHeader";
import PageMeta from "../Common/PageMeta";
import categories from "../../data/categories";
import axios from "axios";
import { withRouter } from "react-router-dom";
class ProductPage extends Component {
  categoryParam = this.props.match ? this.props.match.params.category : null;
  productParam = this.props.match ? this.props.match.params.product : null;
  actionParam = this.props.match ? this.props.match.params.action : null;

  state = {
    products: [],
    filteredProducts: [],
    productGallery: {
      showGallery: false,
      galleryImages: [],
    },
    meta: {
      title: "Products",
      description: "Prouduct List",
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setIsLoading(true);

    //set meta
    if (this.categoryParam) {
      const category = categories.find(
        (record) => record.code === this.categoryParam
      );
      if (category) {
        this.setState({
          meta: {
            title: `${category.name} Products`,
            description: `${category.name} Product List`,
          },
        });
      }
    } else if (this.productParam) {
      this.setState({
        meta: {
          title: `${this.productParam}`,
          description: `${this.productParam}`,
        },
      });
    }

    //load products
    axios
      .get("https://api.rcnewchem.com.ph/getproducts")
      .then((response) => {
        const sortProducts = response.data.sort((a, b) => {
          let c = 0;
          if (a.uid > b.uid) {
            c = 1;
          } else if (a.uid < b.uid) {
            c = -1;
          }
          return c;
        });
        this.setState({
          products: sortProducts,
          filteredProducts: sortProducts,
        });
        if (this.categoryParam) {
          this.productFilterByCategory(this.categoryParam);
        }
        this.props.setIsLoading(false);

        if (this.productParam && this.actionParam) {
          if (this.actionParam === "images") {
            const product = sortProducts.find(
              (record) => record.product_name === this.productParam
            );
            if (product) {
              const galleryImages = product.images.map((record) => {
                return {
                  src: `/${record}`,
                  thumbnail: `/${record}`,
                  srcSet: [],
                  caption: `${product.product_name}`,
                };
              });
              this.setState({
                meta: {
                  title: `${product.product_name}`,
                  description: `${
                    product.product_name
                  } ${product.product_othername.join(" ")}`,
                },
                productGallery: {
                  showGallery: true,
                  galleryImages: galleryImages,
                },
              });
              const scrolledEl = document.querySelector(
                `#product_${product.uid}`
              );
              scrolledEl.scrollIntoView({ behavior: "smooth" });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleViewImage = (productItem) => {
    const galleryImages = productItem.images.map((record) => {
      return {
        src: `/${record}`,
        thumbnail: `/${record}`,
        srcSet: [],
        caption: `${productItem.product_name}`,
      };
    });
    this.setState({
      productGallery: {
        galleryImages: galleryImages,
        showGallery: true,
      },
    });
    this.props.history.push(`/products/${productItem.product_name}/images`);
  };

  handleCloseGallery = () => {
    this.setState({
      productGallery: { showGallery: false, galleryImages: [] },
    });
  };

  productFilterByCategory = (category) => {
    const items = this.state.products.filter((record) => {
      return category === "all" || record.category === category;
    });
    this.setState({
      filteredProducts: items,
    });
  };

  productFilterBySearch = (category, search) => {
    const items = this.state.products.filter((record) => {
      let { product_name, product_othername } = { ...record };
      product_name = product_name.toLowerCase();
      product_othername = product_othername.join(" ").toLowerCase();
      search = search.toLowerCase();
      return (
        (category === "all" || record.category === category) &&
        (product_name.includes(search) || product_othername.includes(search))
      );
    });
    this.setState({
      filteredProducts: items,
    });
  };

  render() {
    const { filteredProducts, meta } = this.state;

    return (
      <>
        <PageMeta title={meta.title} description={meta.description} />
        <PageHeader header="Our Products" />
        <section
          id="contact"
          className="contact"
          style={{ paddingTop: "10px" }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
              >
                <ProductContact />
              </div>

              <div
                className="col-lg-9 d-flex align-items-stretch"
                data-aos="fade-up"
              >
                <div className="info-box">
                  <ProductSearch
                    category={this.categoryParam}
                    productFilterByCategory={this.productFilterByCategory}
                    productFilterBySearch={this.productFilterBySearch}
                  />
                  <ProductList
                    products={filteredProducts}
                    viewImages={this.handleViewImage}
                    closeGallery={this.handleCloseGallery}
                    productGallery={this.state.productGallery}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(ProductPage);
