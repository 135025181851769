import React, { Component } from "react";
import ImgsViewer from "react-images-viewer";

class ProductGallery extends Component {
  state = {
    currImg: 0
  };

  gotoPrevious = () => {
    const { images } = this.props;
    const { currImg } = this.state;
    let newPos = 0;
    if (currImg === 0) {
      newPos = images.length - 1;
    } else {
      newPos = currImg - 1;
    }
    this.setState({
      currImg: newPos
    });
  };

  gotoNext = () => {
    const { images } = this.props;
    const { currImg } = this.state;
    let newPos = 0;
    if (currImg < images.length) {
      newPos = currImg + 1;
    }
    this.setState({
      currImg: newPos
    });
  };

  thumbnailClicked = pos => {
    this.setState({
      currImg: pos
    });
  };

  closeGallery = () => {
    this.setState({
      currImg: 0
    });
    this.props.handleCloseGallery();
  };

  openImage = () => {
    const { images } = this.props;
    const { currImg } = this.state;
    window.open(images[currImg].src, "_tab");
  };

  render() {
    return (
      <ImgsViewer
        imgs={this.props.images}
        currImg={this.state.currImg}
        isOpen={this.props.showGallery}
        onClickPrev={this.gotoPrevious}
        onClickNext={this.gotoNext}
        onClickImg={this.openImage}
        onClose={this.closeGallery}
        onClickThumbnail={this.thumbnailClicked}
        showThumbnails={true}
        backdropCloseable={false}
        closeBtnTitle={"close"}
        leftArrowTitle={"Previous"}
        rightArrowTitle={"Next"}
      />
    );
  }
}

export default ProductGallery;
