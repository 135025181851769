import React, { Component } from "react";
import CompanyProfile from "./CompanyProfile";
import MissionVision from "./MissionVision";
import ContactUs from "../Home/ContactUs";
import PageHeader from "../Common/PageHeader";
import PageMeta from "../Common/PageMeta";
class CompanyPage extends Component {
  state = {};
  render() {
    return (
      <>
        <PageMeta title="Company Profile" description="Company Profile" />
        <PageHeader header="Company Profile" />
        <CompanyProfile />
        <MissionVision />
        <ContactUs {...this.props} />
      </>
    );
  }
}

export default CompanyPage;
