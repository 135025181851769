import React from "react";
import "./App.css";
import Home from "./components/Home/Home";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ContactPage from "./components/Contact/ContactPage";
import ProductPage from "./components/Product/ProductPage";
import CompanyPage from "./components/Company/CompanyPage";
import withLoading from "./components/Hoc/withLoading";
import withInfoModal from "./components/Hoc/withInfoModal";

function App(globalStore) {
  return (
    <>
      <Navigation />
      <BrowserRouter>
        <Switch>
          <Route path="/" render={() => <Home {...globalStore} />} exact />
          <Route
            path="/products"
            render={() => <ProductPage {...globalStore} />}
            exact
          />
          <Route
            path="/companyprofile"
            render={() => <CompanyPage {...globalStore} />}
            exact
          />
          <Route
            path="/products/category/:category"
            render={props => <ProductPage {...globalStore} {...props} />}
            exact
          />
          <Route
            path="/products/:product/:action"
            render={props => <ProductPage {...globalStore} {...props} />}
            exact
          />
          <Route
            path="/contactus"
            render={() => <ContactPage {...globalStore} />}
            exact
          />
          <Route render={() => <Home {...globalStore} />} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default withInfoModal(withLoading(App));
