import React, { Component } from "react";
class Topbar extends Component {
  state = {};
  render() {
    return (
      <section id="topbar" className="d-none d-lg-block">
        <div className="container clearfix">
          <div className="contact-info float-left">
            <i className="icofont-envelope"></i>
            <a href="mailto:contact@example.com">sales@rcnewchemp.com.ph</a>
            <i className="icofont-phone"></i>
            <a href="tel:+6328510486"> +63 2 8851-04-86</a>
          </div>
          <div className="social-links float-right"></div>
        </div>
      </section>
    );
  }
}

export default Topbar;
