import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";

function withLoading(WrappedComponent) {
  return class LoadingModal extends Component {
    render() {
      return (
        <>
          <WrappedComponent {...this.props} />
          <Modal
            show={this.props.isLoading}
            animation={false}
            backdrop="static"
            keyboard={false}
            centered
            size="sm"
          >
            <Modal.Body className="text-center mt-4 mb-3">
              <Spinner animation="border" variant="secondary" />
              <div className="mt-2">Please Wait...</div>
            </Modal.Body>
          </Modal>
        </>
      );
    }
  };
}

export default withLoading;
