import React, { Component } from "react";
import customerdata from "../../data/customers";
class OurCustomers extends Component {
  state = {};
  render() {
    const customers = customerdata.map((data) => {
      return (
        <div className="col-lg-2 col-md-2 col-sm-2 col-3" key={data.code}>
          <img
            src={`/assets/img/customers/${data.code}.png`}
            className="img-fluid"
            alt={`${data.code}`}
          />
        </div>
      );
    });
    return (
      <section id="customers" className="contact">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="section-title">
            <h2>Our Customers</h2>
            <div className="row"> {customers}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default OurCustomers;
