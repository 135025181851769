import React, { Component } from "react";
import ContactUs from "../Home/ContactUs";
import PageHeader from "../Common/PageHeader";
import PageMeta from "../Common/PageMeta";
class ContactPage extends Component {
  state = {};
  render() {
    return (
      <>
        <PageMeta title="Contacts" description="Contact Information" />
        <PageHeader header="Contact Us" />
        <ContactUs {...this.props} hideTitle={true} />
      </>
    );
  }
}

export default ContactPage;
