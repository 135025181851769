import React, { Component } from "react";
class ProductContact extends Component {
  state = {};
  render() {
    return (
      <div className="info-box d-none d-lg-block">
        <i className="bx bx-envelope"></i>
        <h3>Email Us</h3>
        <p>
          office@rcnewchem.com.ph
          <br />
          sales@rcnewchem.com.ph
          <br />
        </p>
        <i className="bx bx-phone-call"></i>
        <h3>Call Us</h3>
        <p>
          +63 (2) 8851-04-86
          <br />
          +63 (2) 8851-04-92
          <br />
          +63 (2) 8851-09-47
          <br />
          +63 (2) 8851-05-01
          <br />
          +63 922 846 5275
          <br />
          +63 917 624 1973
          <br />
          +63 917 820 1974
          <br />
          +63 917 836 1975
          <br />
        </p>
        <h3>Fax</h3>
        <p>+63 (2) 8851-04-98</p>
        <br />
        <i className="bx bx-map"></i>
        <h3>Address</h3>
        <p>
          G/F Sertesa Bdlg., No. 73 Clemente Jose St. Malibay, Pasay City – 1300
          Philippines
        </p>
        <br />
        <p>
          <a href={`https://goo.gl/maps/cT5r8ZVe5J7iQu1V7`} target="_tab">
            <button>View on Google Maps</button>
          </a>
        </p>
      </div>
    );
  }
}

export default ProductContact;
