import React, { Component } from "react";
import categorydata from "../../data/categories";
import { withRouter } from "react-router-dom";
class ProductSearch extends Component {
  state = {
    category: "all",
    search: ""
  };
  componentDidMount() {
    this.setState({
      category: this.props.category ? this.props.category : "all"
    });
    const categories = categorydata.map(category => {
      return (
        <option value={category.code} key={category.code}>
          {category.name}
        </option>
      );
    });
    this.setState({ categories: categories });
  }

  handleCategoryChange = event => {
    this.props.productFilterByCategory(event.target.value);
    this.setState({
      category: event.target.value,
      search: ""
    });
    this.props.history.push(`/products/category/${event.target.value}`);
  };

  handleSearchChange = event => {
    const { category } = this.state;
    this.props.productFilterBySearch(category, event.target.value);
    this.setState({
      search: event.target.value
    });
  };

  render() {
    return (
      <>
        <div className="form-group row ml-2 mr-2">
          <div className="col-lg-4 form-inline">
            <label htmlFor="productcategory" className="mr-2">
              Category
            </label>
            <select
              className="form-control"
              id="productcategory"
              style={{ width: "100%" }}
              onChange={this.handleCategoryChange}
              value={this.state.category}
            >
              <option value="all">All</option>
              {this.state.categories}
            </select>
          </div>
          <div className="col-lg-6 form-inline">
            <label htmlFor="productfilter" className="mr-2">
              Search
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="productfilter"
              style={{ width: "100%" }}
              value={this.state.search}
              onChange={this.handleSearchChange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProductSearch);
