import React from "react";
function PageHeader(props) {
  return (
    <>
      <div className="section-title pt-5 vertical-stripes">
        <h2>{props.header}</h2>
      </div>
    </>
  );
}
export default PageHeader;
