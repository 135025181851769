import React, { Component } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

class ContactUs extends Component {
  state = {
    subject: "",
    email: "",
    message: "",
    name: "",
    captcha: ""
  };

  openMaps = () => {
    window.open("https://goo.gl/maps/cT5r8ZVe5J7iQu1V7", "_blank");
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  updateCaptcha = (e) => {
    this.setState({captcha : e})
  }

  sendMessage = (e) => {
    e.preventDefault();
    this.props.setIsLoading(true);
    const data = { ...this.state };
    axios
      .post("https://api.rcnewchem.com.ph/sendemail", data)
      .then((response) => {
        this.props.setIsLoading(false);
        this.props.setInfoModal({
          show: true,
          message: "Thank you. We will contact you as soon as possible.",
          title: "Message Sent Successfully",
        });
      })
      .catch((error) => {
        this.props.setIsLoading(false);
        this.props.setInfoModal({
          show: true,
          message:
            "Sorry we're not able to send your message. Please reach using our contact details in the Contact Us Page",
          title: "Failed to send message",
        });
      });
  };

  render() {
    const { name, email, subject, message, captcha } = this.state;
    const enabled = name && email && subject && message && captcha;
    return (
      <section
        id="contact"
        className="contact"
        style={{ paddingTop: this.props.hideTitle ? "10px" : "60px" }}
      >
        <div className="container">
          <div className="section-title" hidden={this.props.hideTitle}>
            <h2>Contact Us</h2>
          </div>

          <div className="row">
            <div
              className="col-lg-6 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="info-box">
                <i className="bx bx-map"></i>
                <h3>Our Address</h3>
                <p>
                  G/F Sertesa Bdlg., No. 73 Clemente Jose St. Malibay, Pasay
                  City – 1300 Philippines
                </p>
                <a href="/assets/img/map-sketch.jpg" target="_tab">
                  <img
                    src="/assets/img/map-sketch.jpg"
                    className="img-fluid p-2"
                    alt="RC Location Sketch"
                  />
                </a>
                <p>
                  <button onClick={this.openMaps}>View on Google Maps</button>
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="info-box">
                <i className="bx bx-envelope"></i>
                <h3>Email Us</h3>
                <p>
                  office@rcnewchem.com.ph
                  <br />
                  sales@rcnewchem.com.ph
                  <br />
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="info-box ">
                <i className="bx bx-phone-call"></i>
                <h3>Call Us</h3>
                <p>
                  +63 (2) 8851-04-86
                  <br />
                  +63 (2) 8851-04-92
                  <br />
                  +63 (2) 8851-09-47
                  <br />
                  +63 (2) 8851-05-01
                  <br />
                  +63 922 846 5275
                  <br />
                  +63 917 624 1973
                  <br />
                  +63 917 820 1974
                  <br />
                  +63 917 836 1975
                  <br />
                </p>
                <h3>Fax</h3>
                <p>+63 (2) 8851-04-98</p>
              </div>
            </div>

            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
              <form
                action="/"
                method="post"
                className="php-email-form"
                onSubmit={this.sendMessage}
              >
                <div className="form-row">
                  <div className="col-lg-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                      value={name}
                      onChange={this.handleInputChange}
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="col-lg-6 form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                      value={email}
                      onChange={this.handleInputChange}
                    />
                    <div className="validate"></div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    value={subject}
                    onChange={this.handleInputChange}
                    required
                  />
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    data-msg="Please write something for us"
                    placeholder="Message"
                    value={message}
                    onChange={this.handleInputChange}
                    required
                  ></textarea>
                  <div className="validate"></div>
                </div>
                <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <ReCAPTCHA
                  sitekey="6Ld7UHgaAAAAAN03fxcET_IclU1McnQaePZvee9T"
                  onChange={this.updateCaptcha}
                />
                <div className="text-center">
                  <button type="submit" disabled={!enabled} style={{backgroundColor: enabled ? "#ff7f00" : "grey"}}>Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUs;
