import React, { Component } from "react";
import categoriesdata from "../../data/categories";
class OurProducts extends Component {
  state = {};

  render() {
    const categories = categoriesdata.map(data => {
      return (
        <div
          className="col-lg-4 col-md-6 col-sm-3 portfolio-item filter-app"
          key={data.code}
        >
          <a href={`/products/category/${data.code}`}>
            <div className="portfolio-wrap">
              <img
                src={`/assets/img/products/${data.code}.jpg`}
                alt={data.code}
                className="img-fluid"
              />
              <div className="portfolio-info">
                <h4>{data.name}</h4>
              </div>
            </div>
          </a>
        </div>
      );
    });
    return (
      <section id="portfolio" className="portfolio section-bg">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="section-title">
            <h2>Our Products</h2>
            <p>Construction Chemicals and other allied products</p>
          </div>

          <div className="row portfolio-container">{categories}</div>
        </div>
      </section>
    );
  }
}

export default OurProducts;
