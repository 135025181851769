import React, { Component } from "react";
class AboutUs extends Component {
  state = {};
  render() {
    return (
      <section id="about" className="about">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row no-gutters">
            <div className="col-lg-6 video-box">
              <img
                src="/assets/img/logo-3.png"
                className="img-fluid d-none d-lg-block"
                alt="About"
                style={{
                  display: "block",
                  margin: "auto",
                  width: "70%"
                }}
              />
            </div>

            <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
              <div className="section-title">
                <h2>About Us</h2>
                <p className="text-justify">
                  RC New Chemical Resources, Inc. started in the 1970's by
                  manufacturing concrete stabilizing agents and waterproofing
                  additives. Now we are known for being one of the top
                  manufacturers in providing high-class quality construction
                  chemicals and materials. <br />
                  <br />
                  More details on <a href="/companyprofile">company profile </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutUs;
